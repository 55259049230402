[data-page-filters] {
  &.is-filtered {
    .page-filters-clear {
      @apply block;
    }
  }

  .page-header-angled & .page-filters {
    @apply md:ml-0 lg:w-full;
  }
}

.page-filter {
  @apply relative;

  &:not(:first-child):before {
    @apply absolute left-0 top-[10px] bg-gray;

    @screen md {
      content: '';
      width: 1px;
      height: calc(100% - 20px);
    }
  }

  .js-dropdown {
    @apply h-[50px];
  }
}

.page-filters-clear {
  @apply hidden;
}

.page-header {
  --filter-controls-text-color: theme('colors.green.DEFAULT');

  &.page-header-as-blue {
    --filter-controls-text-color: theme('colors.white');
  }
}
